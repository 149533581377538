import axios from 'axios';
import { getCookie } from './cookie';

const instance = axios.create({
  baseURL : process.env.REACT_APP_LOGIN_URL,
})

const tokeninstance = axios.create({
  baseURL : process.env.REACT_APP_API_URL,
})

instance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {return response},
  (error) => {return Promise.reject(error)}
)


tokeninstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Access_token");
    if(token) {
      config.headers["authorization"] = token;
    }
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

tokeninstance.interceptors.response.use(
  (response) => {return response},
  async function (error) {
    // access token 만료시 refresh 토큰 사용하여 토큰 발급
    if(error.response?.status === 401) {
      console.log("401 UnAuthorized")
      try {
        const config = {
          headers : { "refreshtoken" : decodeURIComponent(getCookie('Refreshtoken')) }
        }
        const res = await instance.post('/auth/refresh',{},config);
        const token = res.headers.get('authorization');
        localStorage.setItem("Access_token", token)
        return axios(error.config)
      } catch(e) {
        console.error(e)
      }
    }
    return Promise.reject(error)
  }
)


export { instance, tokeninstance}