import './App.css';
import './Font.css';
import { createBrowserRouter, BrowserRouter as Router, RouterProvider } from 'react-router-dom';
import { Home, Layout, Main, Create, Routing, Login, Contact, OnlyLogin, Status, Signup } from './pages';
import { PrivateRoute } from './pages/PrivateRoute';

function App() {

  const router = createBrowserRouter([
    {
      path : '/',
      element : <Layout/>,
      children : [
        {
          path: '/',
          element: <PrivateRoute component={<Home />} />,
        },
        {
          path: '/dashboard',
          element: <PrivateRoute component={<Main />} />,
        },
        {
          path: '/create',
          element: <PrivateRoute component={<Create />} />,
        },
        {
          path: '*',
          element: <PrivateRoute component={<Routing />} />,
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/contact',
          element: <PrivateRoute component={<Contact />} />,
        },
        {
          path : '/oauth/:platform',
          element : <OnlyLogin/>
        },
        {
          path : '/status',
          element : <Status/>
        },
        {
          path : '/policy',
          element : <Signup/>
        }
      ]
    },
  ])


  return (
    <div className="App">
      {/* <Router>
        <Layout/>
      </Router> */}
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
