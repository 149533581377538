import { styled } from 'styled-components';
import { close, copy } from '../assets';
import { useEffect, useState } from 'react';

const S = {
  Ssh : styled.section`
    width : 100vw;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    z-index : 3;
    background : #e7e7e799;

    display : flex;
    align-items : center;
    justify-content : center;
  `,

  Box : styled.section`
    width : 30vw;
    height : 80vh;
    background : #434C61e6;

    padding : 3vw 5vh;
    box-sizing : border-box;

    color : white;
    text-align : start;
  `,
  Top : styled.div`
    text-align : end;
  `
  , 
  Exit : styled.img`
    cursor : pointer;

    width : 30px;
    height : 30px;
  `,

  Title : styled.h3`
    font-size : 36px;
    margin : 0;
    text-align : start;
  `,
  SubText : styled.div`
    font-size : 24px;
    margin : 5px 0;
    & > u {
      cursor : pointer;
    }

    &.detail {
      margin : 10px 25px;
    }

    &.title {
      margin : 15px 0;
    }

    &.danger {
      color : #ff7373;
    }
  `,

  Server_box : styled.div`
    width : 100%;
    height : 60px;
    background : #d9d9d9;
    padding : 20px;
    box-sizing : border-box;

    margin : 10px 0; 

    display : flex;
    align-items: center;
    justify-content : space-between;
  `,
  Server_text : styled.span`
    color : black;
  `

}
const Ssh = ({closemodal, keydata, header, name}) => {

  const [fileName, setFileName] = useState(null)

  const copytext = () => {
    navigator.clipboard.writeText('hello!!')
    alert('복사되었습니다.')
  }

  useEffect(() => {
    const blobData = new Blob([keydata],{type:header})

    let filename = "unknown.pem";
    const [fileNameMatch] = name.split(';').filter((str) => str.includes("filename"));
    if (fileNameMatch) [,filename] = fileNameMatch.split("=");
    setFileName(filename)

    const link = document.getElementById('download')
    link.download = filename
    link.href = URL.createObjectURL(blobData);
    link.target = "_blank"
    
    link.click();

    URL.revokeObjectURL(blobData)

  }, [])

  return (
    <S.Ssh>
      <S.Box>
        <S.Top>
          <S.Exit src={close} onClick={closemodal}/>
        </S.Top>
        

        <S.Title>서버 접속 방법</S.Title>
        <S.SubText className="title">* pem key 이용</S.SubText>
        <S.SubText className="detail danger">pem key 분실 시 재다운로드 불가능</S.SubText>
        <S.SubText className="detail">1 ) pem key 다운로드</S.SubText>
        <S.SubText className="detail">자동으로 다운로드 되지 않을 시
          <br/>
          <u>
            <a id='download'>
            download
            </a>
          </u> 클릭하여 저장
        </S.SubText>
        <S.SubText className="detail">2 ) 명령어로 서버 접속</S.SubText>
        <S.Server_box>
          <S.Server_text>ssh -i {fileName} -p 포트 ubuntu@192.168.1.190</S.Server_text>
          <S.Exit src={copy} onClick={copytext}/>
        </S.Server_box>

        <S.SubText className="title">* 홈페이지 버튼 이용</S.SubText>
        <S.SubText className="detail">1 ) 해당 인스턴스 우측 상단 ... 클릭</S.SubText>
        <S.SubText className="detail">2 ) '서버 접속' 클릭</S.SubText>
        
      </S.Box>
    </S.Ssh>
  )
}

export default Ssh;