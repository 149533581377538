import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { guest, logo, menu, profile } from '../assets';
import { getCookie, removeCookie } from '../api/cookie';


const S = {
  Nav : styled.nav`
    width : 100%;
    height : 58px;

    background : #212024;
    position : sticky;
    left : 0;
    top : 0;
    z-index : 98;
  `,
  Menu : styled.section`
    width : 100%;
    height : 100%;
    padding : 20px 20px 14px;
    box-sizing : border-box;
    
    display : flex;
    align-items : center;

    justify-content : space-between;
  `,

  Menu_section : styled.section`
    display : flex;

    &.main {
      height : 100%;
    }

    &.sub {
      padding : 14px 0 0;
      margin-bottom : 24px;
    }
  `,

  Title : styled.img`
    width : 112px;
    margin : 0 20px;

    cursor : pointer;
  `,

  Profile : styled.div`
    width : 36px;
    height : 36px;
    border-radius : 36px;
    display : flex;
    justify-content : center;
    align-items : center;

    position : relative;

    &.user {
      background : no-repeat center/cover url(${profile})
    }
      
    &.guest {
      
      padding : 7px;
      box-sizing : border-box;
      background : #424145;
    }

    & img {
      width : 24px;
      height : 24px;
    }

    &:hover {
      & .bubble {
        visibility : visible;
      }
    }
  `,

  Logo : styled.img`
    cursor : pointer;

    &.menu {
      width : 24px;
      height : 24px;
    }
  `,

  Open_Nav_BG : styled.section`
    width : 100%;
    max-width : 768px;
    height : 100%;
    background : #000000B3;

    position : fixed;
    top : 0;
    left : 0;
    z-index : 99;

    @media(min-width : 767px) {
      margin : 0 calc(50% - 384px);
    }
  `,
  Open_Nav : styled.section`
    width : 240px;
    height : 100%;
    background : #212024;

    padding : 6px 38px 60px 20px;
    box-sizing : border-box;

    display : flex;
    flex-direction : column;
  `,

  Menu_item : styled.div`
    color : #fff;
    padding : 15px 20px;
    margin : 10px 0;
    text-align : start;

    font-size : 1.375rem;
    font-weight : 700;

    cursor : pointer;
    border-radius : 10px;

    display : flex;

    &.select {
      background : #394CF2;
    }

    &.vroom {
      justify-content :space-between;
    }
      
    & span {
      font-family : 'Miama';
      font-size : 1.5rem;
    }
  `,
  Submenu_item : styled.div`
    color : #BDBCC1;
    padding : 16px 20px;
    text-align : start;
    cursor : pointer;
  `,
  Menu_sec : styled.section`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
    justify-content : space-between;

    overflow-y : scroll;
  `,

  Bubble : styled.div`
    position : absolute;
    top : 36px;
    right : 0;
    visibility : hidden;
    
    display : flex;
    flex-direction : column;
    align-items : flex-end;
  `,
  Bubble_top : styled.div`
    width : 0;
    height : 0;
    border-bottom : 15px solid #fff;
    border-top : 10px solid transparent;
    border-left : 10px solid transparent;
    border-right : 10px solid transparent;
    margin-right : 10px;
  `,
  Bubble_content : styled.div`
    // width : 120px;
    background : #fff;
    border-radius : 10px;
    padding : 10px;
  `
}



const Nav = () => {

  const navigate = useNavigate();

  const name = decodeURIComponent(getCookie('name'))
  const role = decodeURIComponent(getCookie('role'))
  const email = decodeURIComponent(getCookie('email'));

  // ver2
  const [isopen, setIsopen] = useState(false);
  const [select, setSelect] = useState('')

  useEffect(() => {
    const path = window.location.pathname;
    setIsopen(false)
    if(path === '/status') {
      setSelect('status')
    } else if (path === '/dashboard' || path === '/create') {
      setSelect('pods')
    } else if (path === '/') {
      setSelect('home')
    } else if (path === '/contact') {
      setSelect('contact')
    } else {
      setSelect('')
    }
  }, [window.location.pathname])

  const logout = () => {
    localStorage.removeItem("Access_token")
    removeCookie('Refreshtoken')
    removeCookie('name')
    removeCookie('role')
    navigate('/login')
  }

  const menuClick = (sts, e = null) => {
    if (sts === 'logout') {
      e.preventDefault();
      logout();
    } else if (sts === 'home') {
      navigate('/')
    } else if (sts === 'pods') {
      navigate('/dashboard')
    } else if (sts === 'contact') {
      navigate('/contact')
    } else if (sts === 'status') {
      navigate('/status')
    } else {
      alert('서비스 준비중 입니다.')
    }
  }

  const navRef = useRef();

  const clickevent = (e) => {
    if(navRef?.current && !navRef?.current.contains(e.target)) {
      setIsopen(false)
    }
  }

  const [isguest, setIsguest] = useState(true);

  useEffect(() => {
    if(role === 'guest' || role === 'undefined') {
      setIsguest(true)
    } else {
      setIsguest(false)
    }
  }, [role])

  return (
    <>
    <S.Nav>

      <S.Menu>
        <S.Menu_section className='main'>
          <S.Logo src={menu} className='menu' onClick={() => setIsopen(!isopen)} alt='menu_icon'/>
          <S.Title onClick={() => navigate('/')} src={logo} alt="logo_jikji"/>
        </S.Menu_section>
        <S.Profile className={isguest ? 'guest' : 'user'}>
          {isguest &&
          <img src={guest} alt='guestimg'/>
          }
          {/* <S.Bubble className='bubble'>
            <S.Bubble_top/>
            <S.Bubble_content>
              {name}({email})
            </S.Bubble_content>
          </S.Bubble> */}
        </S.Profile>
      </S.Menu>
      
      {isopen &&
        <S.Open_Nav_BG onClick={e => clickevent(e)}>
          <S.Open_Nav ref={navRef}>
            <S.Menu_section className='sub'>
              <S.Logo src={menu} className='menu' onClick={() => setIsopen(!isopen)} alt='menu_icon'/>
              <S.Title onClick={() => navigate('/')} src={logo} alt='logo_jikji'/>
            </S.Menu_section>

            <S.Menu_sec>
              <div>
                <S.Menu_item className={select==='home' ? 'select' : ''} onClick={() => menuClick('home')}>Home</S.Menu_item>
                <S.Menu_item className={select==='pods' ? 'select' : ''} onClick={() => menuClick('pods')}>Pods</S.Menu_item>
                <S.Menu_item className={select==='status' ? 'select' : ''} onClick={() => menuClick('status')}>
                  VR ShowRoom <span>Beta</span>
                </S.Menu_item>
                <S.Menu_item className='' onClick={() => menuClick('')}>Serverless</S.Menu_item>
                <S.Menu_item className='' onClick={() => menuClick('')}>Billing</S.Menu_item>
                <S.Menu_item className='' onClick={() => menuClick('')}>Storage</S.Menu_item>
                <S.Menu_item className='' onClick={() => menuClick('')}>Setting</S.Menu_item>
                <S.Menu_item className={select==='contact' ? 'select' : ''} onClick={() => menuClick('contact')}>Contact Us</S.Menu_item>
              </div>
              
              <div>
                <S.Submenu_item onClick={(e) => menuClick('logout', e)}>Logout</S.Submenu_item>
              </div>
            </S.Menu_sec>
          </S.Open_Nav>
        </S.Open_Nav_BG>
      }
    </S.Nav>

    </>
  )
}

export default Nav;