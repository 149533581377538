import {styled} from 'styled-components';
import {bubble, close, endpoints, gpu, more, storage, vcpu} from '../assets';
import {Card, Guest} from '../components';
import { getCookie } from '../api/cookie';
import { useEffect, useState } from 'react';
import { getInstancelist } from '../api/api';

const S = {
  Home : styled.section`
    padding : 20px 20px 0;
  `,
  Icon : styled.img`
    &.more {
      position : absolute;
      right : 20px;
      top : 30px;
  
      width : 24px;
      height : 24px;
      cursor : pointer;
    }
    &.resouce {
      width : 56px;
      height : 56px;
      margin-right : 20px
    }
  `,
  Top : styled.section`
    width : 100%;
    background : #394CF2;
    border-radius : 20px;

    padding : 30px 20px 90px;
    box-sizing : border-box;
    text-align : start;

    position : relative;
  `,
  Top_title : styled.div`
    color : #ffffff99;
    font-size : 0.875rem;
    font-weight : 600;
    margin : 0 0 8px;

    &.last {
      margin-top : 30px;
    }
  `,
  Top_data : styled.div`

  `,
  Top_data_text : styled.span`
    color : #fff;
    font-size : 1.875rem;
    font-weight : 700;

    &.dollar {
      font-weight : 300;
    }
    &.unit {
      font-size : 1rem;
    }
  `,

  Graph_section : styled.div`
    width : 100%;
    margin-top : 30px;

    display : flex;
    justify-content : space-between;
    align-items : end;

    & > .select {
      & div.graph {
        background : #01EF9F;
      }
      & span.graph {
        color : #fff;
      }
    }
  `,
  Graph : styled.div`
    width : 32px;
    background : #BAC4FE;
    border-radius : 100px 100px 0 0;
    margin-bottom : 12px;
    cursor : pointer;
  `,
  Graph_data : styled.div`
    position : relative;
  `,
  Graph_text : styled.span`
    color : #ffffff99;
    font-size : 0.75rem;
    font-weight : 600;
    
  `,
  Graph_bubble : styled.div`
    position : absolute;
    background : no-repeat center/contain url(${bubble});
    width : 147px;
    height : 50px;
    bottom : -60px;
    right : 0;

    &.left {
      transform : scaleX(-1);
      left : 0;

      span {
        transform : scaleX(-1);
      }
    }

  `,
  Graph_bubble_text : styled.span`
    color : #000;
    padding : 21px 20px 10px 20px;
    font-size : 1rem;
    font-weight : 700;

    position : absolute;

  `,
  Title : styled.div`
    color : #fff;
    font-size : 1.5rem;
    font-weight : 400;
    letter-spacing : -0.24px;
    text-align : start;

    margin : 40px 0 20px;
  `,

  Resouce_section : styled.section`
    display : flex;
    overflow-x : scroll;
    gap : 20px;

    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 *

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Resouce_card : styled.div`
    min-width : 200px;
    background : #424145;
    border-radius : 20px;
    padding : 30px 20px;
    box-sizing : border-box;

    display : flex;
    align-items : center;

  `,
  Resouce_content : styled.div`
    margin-left : 20px;
  `,
  Resouce_title : styled.div`
    text-align : start;

    color : #fff;
    opacity : 0.6;
    font-size : 0.875rem;
    font-weight: 600;
  `,
  Resouce_data : styled.div`
    text-align : start;
    color : #fff;
    font-size : 1.875rem;
    font-weight : 700;
  `

}

const Home = () => {

  const role = decodeURIComponent(getCookie('role')) || 'guest';
  const [guestopen, setGuestopen] = useState(true);
  const [carddata, setCarddata] = useState();
  const [isguest, setIsguest] = useState(true);

  const getdata = async () => {
    try {
      const res = await getInstancelist();
      setCarddata(res.data.data[0])
    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getdata();
  }, [])

  useEffect(() => {
    if (role !== 'guest' && role !== undefined) {
      setIsguest(false)
    }
  }, [role])

  const GraphComp = ({height, text, select=false, setSelect, idx}) => {
    return(
      <S.Graph_data className={select ? 'select' : ''}>
        <S.Graph style={{height}} className='graph' onClick={() => setSelect(text)}/>
        <S.Graph_text className='graph'>{text}</S.Graph_text>
        {select &&
          <S.Graph_bubble className={parseInt(idx) < 3 ? 'left' : ''}>
            <S.Graph_bubble_text>Pods : $ 10.00</S.Graph_bubble_text>
          </S.Graph_bubble>}
      </S.Graph_data>
    )
  }

  const ResourceCard = ({src, title, data}) => {
    return (
      <S.Resouce_card>
        <S.Icon className='resource' src={src} alt='title'/>
        <S.Resouce_content>
          <S.Resouce_title>{title}</S.Resouce_title>
          <S.Resouce_data>{data}</S.Resouce_data>
        </S.Resouce_content>
      </S.Resouce_card>
    )
  }

  const TopComp = () => {
    const [select, setSelect] = useState(4);

    const data = [
      { idx:0, height : 50, text : 'Oct.11' },
      { idx:1, height : 80, text : 'Oct.12' },
      { idx:2, height : 60, text : 'Oct.13' },
      { idx:3, height : 100, text : 'Oct.14' },
      { idx:4, height : 120, text : 'Oct.15' },
    ]
    return (
      <S.Top>
        <S.Icon className='more' src={more} alt='more_icon'/>
        <S.Top_title>Current Spend</S.Top_title>
        <S.Top_data>
          <S.Top_data_text className='dollar'>$</S.Top_data_text>
          <S.Top_data_text>13.12</S.Top_data_text>
          <S.Top_data_text className='unit'>/hour</S.Top_data_text>
        </S.Top_data>
        <S.Top_title className='last'>Rolling Average</S.Top_title>
        <S.Top_data>
          <S.Top_data_text className='dollar'>$</S.Top_data_text>
          <S.Top_data_text>150.36</S.Top_data_text>
          <S.Top_data_text className='unit'>/day</S.Top_data_text>
        </S.Top_data>

        <S.Graph_section>
          {data.map((item) => (
            <GraphComp height={`${item.height}px`} text={item.text} select={select===item.idx} key={item.idx} idx={item.idx} setSelect={() => setSelect(item.idx)}/>
          ))}
        </S.Graph_section>

      </S.Top>
    )
  }

  return (
    <S.Home>
      {isguest ?
        <>
          {guestopen &&
            <Guest comp={<TopComp/>} closebtn={() => setGuestopen(false)} text=" 할 수 있습니다." acctext="Pods 생성 시 사용량을 모니터링"/>
          }
        </>
        :
        <TopComp/>
      }

      <S.Title>Resource</S.Title>
      <S.Resouce_section>
        <ResourceCard src={gpu} title='GPUS' data='2'/>
        <ResourceCard src={vcpu} title='VCPU' data='4'/>
        <ResourceCard src={storage} title='STORAGE' data='3'/>
        <ResourceCard src={endpoints} title='ENDPOINTS' data='5'/>
      </S.Resouce_section>
      
      {role &&
        <>
          <S.Title>Recent Pods</S.Title>
          <Card data={carddata}/>
        </>
      }

    </S.Home>
  )
}

export default Home;