import {styled} from 'styled-components';

const S = {
  Button : styled.button`
    border-radius : 10px;
    border : 0;
    color : #fff;
    font-size : 1.125rem;
    font-weight : 700;
    padding : 14px 24px;
    font-family : 'Pretendard';
    cursor : pointer;

    &.default {
      background : #394CF2;
    }

    &.active {
      background : #1F29CE;
    }

    &.disable {
      background : #9E9DA1;
    }

    &.clear {
      border : 1.2px solid #fff;
      background : none;
    }

    &:disabled {
      cursor : default;
    }
  `
}
const Button = ({onClick, state='default', text, full=false, disabled=false}) => {
  return (
    <S.Button className={state} onClick={onClick} style={full ? {width : '100%', padding : '20px 24px', marginTop : '20px'} : {}} disabled={disabled}>
      {text}
    </S.Button>
  )
}

export default Button;