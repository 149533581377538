import {styled} from 'styled-components';
import { close } from '../assets';

const S = {
  Guest_section : styled.section`
    width : 100%;
    background : #424145;
    padding: 20px;

    position : relative;
    left : -20px;
  `,
  Guest_info : styled.div`
    margin-bottom : 20px;
    display : flex;
    justify-content : space-between;
  `,
  Guest_text : styled.div`
    color : #fff;
    font-size : 1rem;
    font-weight : 500;

    & span {
      color : #01EF9F;
    }
  `,
  Icon : styled.img`
    width : 24px;
    height : 24px;
    cursor : pointer;
  `
}

const Guest = ({comp, closebtn, acctext, text}) => {
  return (
    <S.Guest_section>
      <S.Guest_info>
        <S.Guest_text><span>{acctext}</span>{text}</S.Guest_text>
        <S.Icon src={close} onClick={closebtn}/>
      </S.Guest_info>
      {comp}
    </S.Guest_section>
  )
}

export default Guest;