import { BeatLoader } from "react-spinners";
import { styled } from 'styled-components';

const S = {
  Loading : styled.section`
    width : 100vw;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    z-index : 100;
    background : ${props => props.bg};

    display : flex;
    align-items : center;
    justify-content : center;
  `
}

const Loading = ({state, color = '#11192A', bg='#e7e7e799'}) => {
  return (
    <S.Loading bg={bg}>
      <BeatLoader color={color} loading={state} size={50}/>
    </S.Loading>
  )
}

export default Loading;