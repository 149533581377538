import { styled } from 'styled-components';
import { close } from '../assets';
import { postState } from '../api/api';

const S = {
  ModalSection : styled.section`
    width : 100vw;
    height : 100vh;
    background : #e7e7e799;

    position : fixed;
    top : 0;
    left : 0;
    z-index : 5;
    
    display : flex;
    justify-content : center;
    align-items : center;
  `,
  Modal : styled.section`
    width : 30%;
    height : 30%;
    background : #11192A;
    border-radius : 20px;
    padding : 22px;
    box-sizing : border-box;

    display : flex;
    flex-direction : column;
    justify-content : space-between;

    @media(max-width : 1024px) {
      width : 80vw;
      height : 200px;
      padding : 15px;
    }
  
  `,
  Top_section  :styled.section`
    display : flex;
    justify-content : end;
  `,
  Close : styled.img`
    cursor : pointer;
  `,
  Text : styled.p`
    font-size : 34px;
    color : #ffffff;

    @media(max-width : 1024px) {
      font-size : 27px;
    }
  `,
  Button_section : styled.section`
    display : flex;
    justify-content:center;
  `,
  Button : styled.button`
    width : 45%;
    height : 50px;
    margin : 0 3%;
    cursor : pointer;
    font-size : 16px;
  `
}

const Modal = ({closemodal, status, id, name}) => {

  let text = ""
  if (status === "stop") {
    text = "중지하시겠습니까?";
  } else if (status === "restart") {
    text = "재시작하시겠습니끼?";
  } else if (status === "delete") {
    text = "종료하시겠습니까?"
  }

  const modal = (state) => {
    if(state === 'close') {
      closemodal()
    } else {
      postState(status, id, name);
      closemodal(status)
    }
  }

  
  return (
    <S.ModalSection>
      <S.Modal>
        <S.Top_section>
          <S.Close src={close} onClick={() => modal("close")}/>
        </S.Top_section>
        <S.Text>
          {text}
        </S.Text>

        <S.Button_section>
          <S.Button onClick={() => modal("yes")}>확인</S.Button>
          <S.Button onClick={() => modal("close")}>취소</S.Button>
        </S.Button_section>
      </S.Modal>
    </S.ModalSection>
  )
}

export default Modal;