import { keyframes, styled } from 'styled-components';
import { download, dropdown_grey, earth, refresh, stop, trash, upload, usim } from '../assets';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../api/cookie';
import Button from './Button';
import { postState } from '../api/api';
import Loading from './Loading';

const scaleInHorLeft = keyframes`
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
`

const S = {
  Card : styled.div`
    width : 100%;
    background : #424145;
    border-radius : 20px;
    padding : 30px 20px;
    box-sizing : border-box;

    margin : 10px 0;
  
  `,
  Top : styled.section`
    display : flex;
    align-items : center;
    justify-content : space-between;
    max-width : 100%;
    margin : 0 0 30px;
  `,
  Title : styled.h3`
    color : #fff;
    font-size : 1.5rem;
    font-weight : 600;
    margin : 0;
    text-align : start;
    overflow : hidden;
    white-space : nowrap;
    text-overflow : ellipsis;
  `,
  State : styled.div`
    background : rgba(1, 239, 159, 0.08);
    border-radius : 17px;
    border : 1px solid #01EF9F;
    padding : 6px 12px;

    color : #01EF9F;
    font-size : 0.8125rem;
    font-weight : 700;
    line-height: 120%;

    &.stopped {
      border-color : #FF4848;
      color : #FF4848;
    }
  `,

  Content : styled.div`
    width : 100%;
    padding : 20px;
    margin : 10px 0;
    box-sizing : border-box;

    border : 1px solid #616064;
    border-radius : 10px;
    color : #E0DFE4;

    overflow : hidden;
    white-space : nowrap;
    text-overflow : ellipsis;

    text-align : start;

    & div {
      color : #ffffff99;
      margin-top : 5px;
    }
  `,
  SubContent_section : styled.section`
    width : 100%;
    display : grid;
    gap : 10px;
    grid-template-columns : 1fr 1fr;
  `,
  SubContent : styled.div`
    padding : 6px 12px;
    box-sizing : border-box;

    border : 1px solid #616064;
    border-radius : 100px;
    color : #E0DFE4;

    display : flex;
    justify-content : flex-start;

    & img {
      width : 20px;
      height : 20px;
      margin-right : 5px;
    }
    
  `,
  Detail_open : styled.span`
    color : #9E9DA1;
    font-size : 1rem;
    font-weight : 600;

    display : flex;
    align-items : center;
    
    margin : 32.5px 0;

    & span, img {
      cursor : pointer;
    }
  `,

  Icon : styled.img`
    &.reverse {
      transform : rotate(0.5turn)
    }
      
    &.refresh {
      cursor : pointer;
      margin-left : 5px;
    }
  `,

  Detail : styled.section`
    color : #fff;
  `,
  Title_section : styled.section`
    display : flex;
    align-items : center;
    justify-content : space-between;
    margin : 30px 0;
  `,
  Title_title : styled.span`
    font-size : 1rem;
    font-weight : 500;
  `,
  Refresh_section : styled.section`
    display : flex;
    aling-items : center;

    color : #ffffff99;
  `,

  Detail_title : styled.div`
    text-align : start;
    font-size : 0.875rem;
    font-weight : 400;
    margin : 30px 0 5px;
  `,
  Detail_content : styled.div`
    display : flex;
  `,
  Detail_content_title : styled.div`
    font-size : 0.875rem;
    color:#ffffff99;
    width : 70px;
    text-align : start;
  `,
  Detail_graph : styled.div`
    width : 70%;
    height : 6px;
    margin : 5.5px 15px;
    background : #616064;
    border-radius : 10px;

    position : relative;
  `,
  Detail_graph_data : styled.div`
    height : 100%;
    background : yellow;
    border-radius : 10px;

    position : absolute;
    left : 0;
    top : 0;

    
    &.animate {
      animation : ${scaleInHorLeft} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.pink {
      background : #EA6ADB;
    }

    &.green {
      background : #01EFA0;
    }

    &.purple {
      background : #7B86FA;
    }

    &.yellow {
      background : #EED673;
    }
  `,
  Detail_data : styled.div`
    width : 10%;
    font-size : 0.875rem;
    text-align : end;
  `,

  Line : styled.hr`
    width : 100%;
    height : 1px;
    background-color : #616064;
    color : #616064;
    border-width : 0;
    margin : 30px 0;
  `,

  Bottom : styled.section`
    display : flex;
    justify-content : space-between;
  `,
  Button : styled.div`
    width : 48px;
    height : 48px;

    background : #616064;
    border-radius : 100px;
    padding : 12px;
    box-sizing : border-box;
    display : flex;
    justify-content : center;
    align-items : center;

    margin-right : 10px;

    cursor : pointer;

    ${props => props.disabled ? 
      {
        cursor : 'default'
      }
    : ''}
  `,
  Bottom_btn_section : styled.section`
    display : flex;
  `
}

// const Card = ({data, modal_open}) => {
const Card = ({data, refreshdata}) => {
  
  const [status, setStatus] = useState(data?.status || 'RUNNING');
  const role = decodeURIComponent(getCookie('role')) || 'guest'
  
  // const id = data?.id || '123'
  // const name = data?.name || 'tmp_name'

  const time = data?.value || "02:02:06"
  const [hour, min, sec] = time?.split(":")

  const [startTime, setStartTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(data?.value);

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(status === 'RUNNING') {
      setStartTime(Date.now())
    }
  }, [])

  useEffect(() => {
    let interval = null;
    if(startTime) {
      interval = setInterval(() => {
        let ntime = (Math.floor((Date.now() - startTime) / 1000))
        let nsec = ( parseInt(sec) + ntime ) % 60
        let nmin = (parseInt(min) + Math.floor((parseInt(sec) + ntime)/60)) % 60
        let nhour = parseInt(hour) + Math.floor((parseInt(min) + Math.floor((parseInt(sec) + ntime)/60))/60)

        setElapsedTime(nhour.toString().padStart(2, '0') +"h "+ nmin.toString().padStart(2, '0') + "m " + (nsec).toString().padStart(2, '0')+ "s") 
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [startTime])

  const [open_viewmore, setOpen_viewmore] = useState(false);
  const [isguest, setIsguest] = useState(true);
  const [loading, setLoading] = useState(false);
 
  useEffect(() => {
    if (role !== 'guest' && role !== 'undefined') {
      setIsguest(false);
    }
  }, [role])

  const deletepods = async () => {
    if (!isguest) {
      if(window.confirm('pods를 삭제하시겠습니까')) {
        setLoading(true)
        await postState('delete', data.id, data.name);
        await tmptimer(7000);
        setLoading(false);
        window.location.reload();
      } 
    }
  }

  const connectserver = () => {
    if (!isguest) {
      window.open(data?.terminal, '_blank')
    }
  }

  const tmptimer = async (time) => {
    await new Promise(resolve => setTimeout(resolve, time))
  }


  const Detail = ({data}) => {
    return (
      <>
      <S.Detail_title>{data?.title}</S.Detail_title>
      {data?.graph.map((item, key) => (
        <DetailGraph title={item?.title} data={item?.data} color={data?.color} key={key}/>
      ))}
      </>
    )
  }

  const DetailGraph = ({title, data, color}) => {

    return (
      <S.Detail_content>
        <S.Detail_content_title>{title}</S.Detail_content_title>
        <S.Detail_graph>
          <S.Detail_graph_data style={{width : `${data}%`}} className={animate ? `animate ${color}` : color}/>
        </S.Detail_graph>
        <S.Detail_data>{data}%</S.Detail_data>
      </S.Detail_content>
    )
  }

  const detail_data = [
    {
      title : 'Pods Utilization',
      graph : [
        {
          title : 'CPU',
          data : 100,
        },
        {
          title : 'MEM',
          data : 32
        }
      ],
      color : 'pink'
    },
    {
      title : 'Disk Utilization',
      graph : [
        {
          title : 'Container',
          data : 80,
        },
        {
          title : 'Volume',
          data : 50
        }
      ],
      color : 'green'
    },
    {
      title : 'GPU Utilization',
      graph : [
        {
          title : '0',
          data : 27,
        },
      ],
      color : 'purple'
    },
    {
      title : 'GPU Memory Used',
      graph : [
        {
          title : '0',
          data : 90,
        },
      ],
      color : 'yellow'
    },
  ]

  const open = async () => {
    setOpen_viewmore(!open_viewmore);
    if (!open_viewmore) {
      setAnimate(true);
      await tmptimer(500);
      setAnimate(false)
    } 
  }

  return (
    <S.Card>
      <S.Top>
        <S.Title>{data?.name || 'JIKJI Admin'}</S.Title>
        <S.State className={status === 'RUNNING' ? '' : 'stopped'}>{status === 'RUNNING' ? 'Running' : 'Stopped'}</S.State>
      </S.Top>

      <S.Content>
        {data?.ssh || 'SSH URL'}
        <div>{data?.user || 'user name'}</div>
      </S.Content>
      <S.Content>
        {data?.gpu || 'GPU'}
        <div>6 vCPU 31GB RAM</div>
      </S.Content>
      <S.Content>
        {data?.image || 'IMAGE'}
        <div>On-demand-Secure Cloud</div>
      </S.Content>

      <S.SubContent_section>
        <S.SubContent><img src={earth} alt='icons'/>Ro</S.SubContent>
        <S.SubContent><img src={usim} alt='icons'/>2501 Mbps</S.SubContent>
        <S.SubContent><img src={upload} alt='icons'/>8654 Mbps</S.SubContent>
        <S.SubContent><img src={download} alt='icons'/>8654 Mbps</S.SubContent>
      </S.SubContent_section>

      <S.Detail_open>
        <span onClick={open}>
          {open_viewmore ? 'Show Less' : 'View More'}
        </span>
        <S.Icon onClick={open} src={dropdown_grey} className={open_viewmore ? 'reverse' : ''} alt='dropdown_icon'/>
      </S.Detail_open>
      
      {open_viewmore &&
        <S.Detail>
          <S.Title_section>
            <S.Title_title>Pod Uptime : {elapsedTime}</S.Title_title>

            <S.Refresh_section>
              {/* 28 seconds ago */}
              <S.Icon className='refresh' src={refresh} onClick={refreshdata}/>
            </S.Refresh_section>
          </S.Title_section>

          {detail_data.map((item, key) => (
            <Detail key={key} data={item}/>
          ))}
        </S.Detail>
      }

      <S.Line/>

      <S.Bottom>
        <S.Bottom_btn_section>
          <S.Button disabled={isguest}><S.Icon src={stop} alt='stop_icon'/></S.Button>
          <S.Button disabled={isguest} onClick={deletepods}><S.Icon src={trash} alt='delete_icon'/></S.Button>
        </S.Bottom_btn_section>
        <Button state="clear" text='Connect' onClick={connectserver} disabled={isguest}/>
      </S.Bottom>

      { loading && <Loading state={loading}/> }

    </S.Card>
  )
}

export default Card;
