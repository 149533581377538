import { Routes, Route, useLocation, Outlet } from 'react-router-dom';
import {styled } from 'styled-components';
import { Contact, Create, Home, Login, Main, OnlyLogin, Routing, Status, Signup  } from '../pages';
import { Nav } from '../components';
import {PrivateRoute, LoginRoute } from './PrivateRoute';

const S = {
  App : styled.section`
    background-color: #212024;
    margin : 0 auto;
    padding : 0 0 77px;
    min-height : 100vh;
    box-sizing : border-box;

    @media(min-width : 768px) {
      max-width : 768px;
    }

    &.login {
      padding : 0;
      position : relative;
    }
  `
}


const Layout = () => {
  const location = useLocation();
  const hidenav = ["/login", "/signup", "/oauth/google","/oauth/naver", "/oauth/kakao"]
  return(
    <S.App className={location.pathname==='/login' ? 'login' : ''}>
      {!hidenav.includes(location.pathname) && <Nav/>}
      <Outlet/>
      {/* <Routes>
        <Route path='/' element={<PrivateRoute component={<Home/>}/>}/>
        <Route path='/dashboard' element={<PrivateRoute component={<Main/>}/>}/>
        <Route path='/create' element={<PrivateRoute component={<Create/>}/>}/>
        <Route path='*' element={<PrivateRoute component={<Routing/>}/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/contact' element={<PrivateRoute component={<Contact/>}/>}/>

        
        <Route path='/oauth/:platform' element={<OnlyLogin/>}/>
        <Route path='/status' element={<Status/>} />
        <Route path='/policy' element={<Signup/>} /> */}
        {/* <Route path='/status' element={<KeepAlive><Status/></KeepAlive>} /> */}
        
        {/* <Route path='/login' element={<Login/>}/>
        <Route path='/' element={<Home/>}/>
        <Route path='/dashboard' element={<Main/>}/>
        <Route path='/create' element={<Create/>}/>
        <Route path='*' element={<Routing/>}/>
        <Route path='/contact' element={<Contact/>}/> */}
      {/* </Routes> */}
    </S.App>
  )
}

export default Layout