import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { postCode } from "../api/api";

const OnlyLogin = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const platform = useParams().platform;
  
  async function post(code) {
    try {
      await postCode(code, platform)
      navigate('/')
    } catch{
      navigate('/login')
    }
  }
  useEffect(() => {
    const code = searchParams.get('code')
    post(code)
  }, [])

  return (
    <></>
  )
}

export default OnlyLogin;