import {styled} from 'styled-components';
import { Button } from '../components';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const S = {
  Contact : styled.section`
    padding : 0 20px;
    box-sizing : border-box;
  `,
  Title : styled.h2`
    color : #fff;
    font-size : 1.875rem;
    font-weight : 700;
    margin : 40px 0;
  `,
  Sub_title : styled.div`
    color : #ffffff99;
    font-size : 0.875rem;
    font-weight : 600;
    text-align : start;

    margin : 30px 0 10px;

    &.first {
      margin : 0 0 10px;
    }
  `,
  Input : styled.input`
    width : 100%;
    padding : 18px;
    border-radius : 8px;
    box-sizing : border-box;
    border : 1.5px solid rgba(97, 96, 100, 0.50);
    background: rgba(66, 65, 69, 0.30);
    caret-color : #5B69F6;

    color : #fff;
    font-size : 1.25rem;
    font-weight : 700;

    &:focus {
      outline : 1.5px solid #5B69F6;
    },

    &.error {
      outline : 1.5px solid rgba(241, 74, 74, 0.50);
    }
  `,
  Checkbox_section : styled.section`
    display : flex;
    align-items : center;
    justify-content : flex-start;
    margin : 30px 0 40px;
    cursor : pointer;
  `,
  Checkbox : styled.input`
    width : 24px;
    height : 24px;
    margin-right : 10px;
  `,
  Checkbox_label : styled.label`
    font-size: 0.875rem;
    color : #ffffff99;
    font-weight : 400;
  `,
  Error : styled.div`
    color : #F14A4A;
    font-size : 0.875rem;
    font-weight : 600;
    text-align : start;
    margin : 10px 0;
  `,
}
const Contact = () => {

  const checkRef = useRef();

  const submit = (e) => {
    e.preventDefault();
    if(!checkRef.current.checked) {
      alert('개인정보 수집에 동의해 주세요.')
    } else if(e.target[0].value.trim() === '' || e.target[1].value.trim() === '' || e.target[2].value.trim() === '') {
      alert('모두 작성해 주세요.')
    } else {
      postGetintouch(e);
    }
  }

  const Input = ({title, placeholder, errmsg, name}) => {
    const [error, setError] = useState(false);
    return (
      <>
        <S.Sub_title>{title}</S.Sub_title>
        <S.Input name={name} placeholder={placeholder} className={error ? 'error' : ''} onChange={e => {if(e.target.value.trim() === ''){setError(true)}else{setError(false)}} }/>
        {error && <S.Error>{errmsg}</S.Error>}
      </>
    )
  }

  const formRef = useRef();

  function postGetintouch(e) {
    e.preventDefault();
    // *******key값 변경!!!********
    emailjs.sendForm("service_soinfj4", "template_ot1myy7", formRef.current, "19LP0E-gjU_jO_c75" ).then(
      result => {
        alert("성공적으로 전송하였습니다.");
        formRef.current.reset();
      },
      error => {
        console.log(error.text);
        alert("전송이 실패되었습니다.")
      }
    )

  };

  return (
    <S.Contact>
      <S.Title>Contact Us</S.Title>

      <form ref={formRef} onSubmit={submit}>

        <Input title='Name' placeholder='Please enter your Name' errmsg='Please enter your name' name='from_name'/>
        <Input title='E-mail' placeholder='Please enter your E-mail' errmsg='Please enter your E-mail' name='email'/>
        <Input title='Message' placeholder='Please enter your Message' errmsg='Please enter your Message' name='message'/>

        <S.Checkbox_section>
          <S.Checkbox type='checkbox' ref={checkRef} id='checkbox'/>
          <S.Checkbox_label htmlFor='checkbox'><a href='https://jikjicloud.io/policy' target='_blank' rel="noreferrer">개인정보 수집 및 활용</a>에 동의합니다.</S.Checkbox_label>
        </S.Checkbox_section>

        <Button state="default" text="Submit" full={true} type='submit'/>
      </form>

    </S.Contact>
  )
}

export default Contact;