import { useEffect, useRef} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { styled } from 'styled-components';
import { useBlocker, useNavigate } from "react-router-dom";

const S = {
  Unity : styled.section`
    width : 100%;
    height : calc(100vh - 52px);
    display : flex;

    margin-bottom : -77px;

    & button {
      background : yellow;
      width : 100%;
      height :30px;
      z-index : 10;
    }
  `,
  Loading : styled.section`
    width : 100%;
    height : 100%;
    position : absolute;
    top : 0;
    left : 0;
    z-index : 1;

    display : flex;
    justify-content :center;
    align-items : center;
  `,
  Loading_text : styled.div`
    color : white;
    font-size : 2.5rem;
    font-weight : 700;
  `
}

const Server = () => {

  // gpu
  const {unityProvider, sendMessage, unload, loadingProgression, isLoaded} = useUnityContext({
    loaderUrl : "build/TestBuild.loader.js",
    dataUrl : "build/webgl.data",
    frameworkUrl : "build/build.framework.js",
    codeUrl : "build/build.wasm",
    productName : "Jikji Cloud",
    productVersion : "1.0.0",
    companyName : "N3N"
  });

  // test
  // const {unityProvider, sendMessage, unload, loadingProgression, isLoaded} = useUnityContext({
  //   loaderUrl : "build_test/build.loader.js",
  //   dataUrl : "build_test/webgl.data",
  //   frameworkUrl : "build_test/build.framework.js",
  //   codeUrl : "build_test/build.wasm"
  // });

  const exitFlag = useRef();
  const navigate = useNavigate();

  const blocker = useBlocker(({currentLoaction, nextLocation}) => {
    return !exitFlag.current;
  })

  useEffect(() => {
    if(blocker.state === 'blocked') {
      unload().then(() => {
        exitFlag.current = true;
        if(blocker.location.pathname) {
          navigate(blocker.location.pathname) 
        } else {
          navigate(-1);
        }
      })
    }
  }, [blocker])


  const sendmsg = () => {
    sendMessage("MyGameObject", "ChangeText", "Destroy")
  }

  const handleunload = async () => {
    // sendmsg();
    await unload();
  }

  return (
    <div>
      <S.Unity>
        {/* <button onClick={handleunload}>unload</button> */}
        <Unity unityProvider={unityProvider} style={{width:"100%", height:'100%', margin:'auto 0', zIndex : '2'}}/>
        { !isLoaded &&
          <S.Loading>
            <S.Loading_text>
              {Math.round(loadingProgression * 100)}%
              {/* <Loading state={true} color='#fff' bg='#212024'/> */}
            </S.Loading_text>
          </S.Loading>
        }
      </S.Unity>
    </div>
  )
}

export default Server;