import {styled} from 'styled-components';

const S = {
  Display : styled.section`
    width : 100%;
    height : calc(100vh - 56px);
    margin-bottom : -77px;
  `,
  SignUp : styled.section`
    width : 90%;
    height : 90%;
    background : #eeeeee;
    border-radius : 10px;
    border : 1px solid #000000;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    padding : 40px;
    box-sizing : border-box;
    `,

  Policy : styled.section`
    width : 100%;
    height : 100%;
    background : #f0f0f0;
    border-radius : 10px;
    flex-grow : 1;
    text-align : start;
    box-sizing : border-box;
    font-family : 'SUIT';

    & iframe {
      border : 0;
      background : #ffffff;
    }
  
  `,
}

const SignUp = () => {

  const iframePracyPart = () => {
    return {
      __html : '<iframe src="./Privacy_Policy.html" width="100%" height="100%"></iframe>'
    }
  }
  
  return (
    <S.Display>
      <S.Policy dangerouslySetInnerHTML={iframePracyPart()}/>
    </S.Display>
  )
}

export default SignUp;
